import React from "react";

import useLockBodyScroll from "../../hooks/use-lock-body-scroll"

const ScrollLocker = () => {
  useLockBodyScroll()
  return (
    <React.Fragment>
    </React.Fragment>
  )
}

export default ScrollLocker